import { useQuery } from '@apollo/client';
import { GET_ORDER_MESSAGES } from '@fullcontour/shared-api';
import DOMPurify from 'dompurify';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { timestampFormatter } from '../../../../../transforms';
import { BoxIcon, LoadingOrError } from '../../../../shared';
import { FilesShow } from '../OrderShowFiles/FilesShow';
import OrderMessageImage from './OrderMessageImage';
import OrderShowMessageForm from './OrderShowMessageForm';
import { urlify } from './helpers';

function OrderShowMessages() {
  const { id } = useParams();
  const [previewImage, setPreviewImage] = useState(null);

  const { loading, error, data, refetch } = useQuery(GET_ORDER_MESSAGES, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const imagesSection = useCallback((message) => {
    if (message.imageUrl && message.images.length > 0) {
      return (
        <>
          <OrderMessageImage message={message} setPreview={setPreviewImage} />
          {message.images.map((item) => (
            <OrderMessageImage
              message={item}
              setPreview={setPreviewImage}
              multipleFiles
              key={item.id}
            />
          ))}
        </>
      );
    }
    if (message.imageUrl) {
      return (
        <OrderMessageImage message={message} setPreview={setPreviewImage} />
      );
    }
    return message.images.map((item) => (
      <OrderMessageImage
        message={item}
        setPreview={setPreviewImage}
        multipleFiles
        key={item.id}
      />
    ));
  }, []);

  return (
    <div style={{ verticalAlign: 'top' }}>
      <div className="column is-6">
        <div className="panel">
          <p className="panel-heading">Case Messages</p>
          {loading || error ? (
            <div
              className="panel-block"
              style={{ minHeight: 300, position: 'relative' }}
            >
              <LoadingOrError
                error={error}
                loading={loading}
                title="Case Messages"
              />
            </div>
          ) : data.orderMessages.length > 0 ? (
            data.orderMessages
              .filter((m) => m.systemMessage === false)
              .filter((m) => m.activity === false)
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((message) => (
                <div
                  className="panel-block"
                  key={message.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      width: 48,
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <BoxIcon
                      name="bx-message-detail bx-md"
                      style={{ marginTop: 9 }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <p className="is-italic has-text-grey-dark is-size-7">
                      {timestampFormatter(message.createdAt)}
                    </p>

                    <p className="has-text-black-bis has-text-weight-semibold">
                      {message.userName}
                    </p>

                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {imagesSection(message)}
                    </div>
                    {message.orderMessageableType !== 'User' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(message.message, {
                            ADD_ATTR: ['target', 'href', 'style'],
                            ADD_TAG: ['a'],
                          }),
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          fontWeight: 'inherit',
                        }}
                      >
                        {urlify(message.message)}
                      </span>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <h6 className="title is-6 has-text-grey has-text-centered">
              No Messages have been posted yet
            </h6>
          )}
        </div>
      </div>
      <div className="column is-6" style={{ verticalAlign: 'top' }}>
        {previewImage && (
          <FilesShow selectedFile={previewImage} loadingFileView={false} />
        )}
        {loading || error ? (
          <LoadingOrError
            error={error}
            loading={loading}
            title="Case Messages"
          />
        ) : (
          <OrderShowMessageForm
            orderId={data.order.id}
            id={id}
            orderState={data.order.state}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
}

export default OrderShowMessages;
